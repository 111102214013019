import * as React from 'react';

const SvgSlots = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
		<path
			fill="#9092B2"
			d="M14.179 21H6.314v-.527c0-1.506.36-3.06 1.07-4.62.563-1.234 1.344-2.473 2.322-3.685a23 23 0 0 1 1.985-2.153q-.47.034-.952.034c-1.442 0-2.83-.239-3.997-.68v1.036H3v-7.27h3.742v.676c1.167-.442 2.555-.68 3.997-.68 1.449 0 2.51.195 3.439.633a3.8 3.8 0 0 0 2.067.345 3.7 3.7 0 0 0 1.92-.806l.37-.302L21 4.674l-.304.436a58 58 0 0 0-3.278 5.473c-1.478 2.813-3.24 6.813-3.24 9.89z"
		/>
	</svg>
);

export default SvgSlots;
